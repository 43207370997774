import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Tab,
  Nav,
  Accordion,
} from "react-bootstrap";
import Dotcircle from "../Icon/Dotcircle";
import { Swiper, SwiperSlide } from "swiper/react";
import Line from "../images/line.png";

export default function Roadmap() {
  return (
    <>
      <Tab.Container id="left-tabs-example" defaultActiveKey="2025">
        <Row>
          <Col sm={12} className="">
            <Nav variant="pills" className="justify-content-center">
              <Nav.Item>
                <Nav.Link eventKey="2021">2021</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="2022">2022</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="2023">2023</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="2024">2024</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="2025">2025</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={12} className="">
            <Tab.Content>
              <Tab.Pane eventKey="2021" className="twentyone">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 25,
                    },
                  }}
                  modules={[]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="card-style4">
                      <Card className="p-4 pe-3">
                        <div className="linelabel">
                          <p className="mb-0">Q1 2021</p>
                          <img className="line" alt="Line" src={Line} />
                          <div className="group">
                            <Dotcircle />
                          </div>
                        </div>

                        <div className="innerCard text-left pl-3">
                          {/* <h4 className="mb-3">Research</h4> */}
                          <div>
                            <ul>
                              <li className="small light-grey">
                                Initiation of Mr Mint Project
                              </li>
                              <li className="small light-grey">
                                Operation Team Formation
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-style4">
                      <Card className="p-4 pe-3">
                        <div className="linelabel">
                          <p className="mb-0">Q2 2021</p>
                          <img className="line" alt="Line" src={Line} />
                          <div className="group">
                            <Dotcircle />
                          </div>
                        </div>

                        <div className="innerCard text-left pl-3">
                          {/* <h4 className="mb-3">Research</h4> */}
                          <div>
                            <ul>
                              <li className="small light-grey">
                                Mr Mint Ecosystem Development Initiated
                              </li>
                              <li className="small light-grey">
                                Approval of Various Upcoming Products Completed
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-style4">
                      <Card className="p-4 pe-3">
                        <div className="linelabel">
                          <p className="mb-0">Q3 2021</p>
                          <img className="line" alt="Line" src={Line} />
                          <div className="group">
                            <Dotcircle />
                          </div>
                        </div>

                        <div className="innerCard text-left pl-3">
                          {/* <h4 className="mb-3">Research</h4> */}
                          <div>
                            <ul>
                              <li className="small light-grey">
                                Smart Contract Creation on Testnet
                              </li>
                              <li className="small light-grey">
                                Blueprint of the Ecosystem Approved
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-style4">
                      <Card className="p-4 pe-3">
                        <div className="linelabel">
                          <p className="mb-0">Q4 2021</p>
                          <img className="line" alt="Line" src={Line} />
                          <div className="group">
                            <Dotcircle />
                          </div>
                        </div>

                        <div className="innerCard text-left pl-3">
                          {/* <h4 className="mb-3">Research</h4> */}
                          <div>
                            <ul>
                              <li className="small light-grey">
                                Offline Marketing Initiated
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </Tab.Pane>
              <Tab.Pane eventKey="2022" className="twentytwo">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 25,
                    },
                  }}
                  modules={[]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="card-style4">
                      <Card className="p-4 pe-3">
                        <div className="linelabel">
                          <p className="mb-0">Q1 2022</p>
                          <img className="line" alt="Line" src={Line} />
                          <div className="group">
                            <Dotcircle />
                          </div>
                        </div>

                        <div className="innerCard text-left pl-3">
                          {/* <h4 className="mb-3">Research</h4> */}
                          <div>
                            <ul>
                              <li className="small light-grey">
                                Initiation of the Project
                              </li>
                              <li className="small light-grey">
                                Designed Tokenomics
                              </li>
                              <li className="small light-grey">
                                Mr Mint Whitepaper Creation
                              </li>
                              <li className="small light-grey">
                                ICO Dashboard Devlopment
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-style4">
                      <Card className="p-4 pe-3">
                        <div className="linelabel">
                          <p className="mb-0">Q2 2022</p>
                          <img className="line" alt="Line" src={Line} />
                          <div className="group">
                            <Dotcircle />
                          </div>
                        </div>

                        <div className="innerCard text-left pl-3">
                          {/* <h4 className="mb-3">Research</h4> */}
                          <div>
                            <ul>
                              <li className="small light-grey">
                                Initiated Private & Pre Sale
                              </li>
                              <li className="small light-grey">
                                Staking Platform Live
                              </li>
                              <li className="small light-grey">
                                Online Marketing & PR
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-style4">
                      <Card className="p-4 pe-3">
                        <div className="linelabel">
                          <p className="mb-0">Q3 2022</p>
                          <img className="line" alt="Line" src={Line} />
                          <div className="group">
                            <Dotcircle />
                          </div>
                        </div>

                        <div className="innerCard text-left pl-3">
                          {/* <h4 className="mb-3">Research</h4> */}
                          <div>
                            <ul>
                              <li className="small light-grey">
                                Developed Infrastructure
                              </li>
                              <li className="small light-grey">
                                Expansion of Cryptocurrency Minning
                              </li>
                              <li className="small light-grey">
                                Initiated Public Sale
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-style4">
                      <Card className="p-4 pe-3">
                        <div className="linelabel">
                          <p className="mb-0">Q4 2022</p>
                          <img className="line" alt="Line" src={Line} />
                          <div className="group">
                            <Dotcircle />
                          </div>
                        </div>

                        <div className="innerCard text-left pl-3">
                          {/* <h4 className="mb-3">Research</h4> */}
                          <div>
                            <ul>
                              <li className="small light-grey">
                                Expanded Infrastructure
                              </li>
                              <li className="small light-grey">
                                MintForce Live
                              </li>
                              <li className="small light-grey">
                                Staking Reward Distribution
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                  {/* <SwiperSlide>
                    <div className="card-style4">
                      <Card className="p-4 pe-3">
                        <div className="linelabel">
                          <p className="mb-0">Q1 2024</p>
                          <img className="line" alt="Line" src={Line} />
                          <div className="group">
                            <Dotcircle/>
                          </div>
                        </div>

                        <div className="innerCard text-left pl-3">
                        //   <h4 className="mb-3">Research</h4>
                          <div>
                            <ul>
                              <li className="small light-grey">
                                CEX Listing (MEXC)
                              </li>
                              <li className="small light-grey">
                                DEX Listing (Pancakeswap)
                              </li>
                              <li className="small light-grey">
                                Coin Listing (CMC & CG)
                              </li>
                              <li className="small light-grey">
                                Influencer Onboarding
                              </li>
                              <li className="small light-grey">
                                Sports Product Presale Launch
                              </li>
                              <li className="small light-grey">
                                Quarterly MNT Burning
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-style4">
                      <Card className="p-4 pe-3">
                        <div className="linelabel">
                          <p className="mb-0">Q1 2024</p>
                          <img className="line" alt="Line" src={Line} />
                           <div className="group">
                            <Dotcircle/>
                          </div>
                        </div>

                        <div className="innerCard text-left pl-3">
                        //   <h4 className="mb-3">Research</h4>
                          <div>
                            <ul>
                              <li className="small light-grey">
                                CEX Listing (MEXC)
                              </li>
                              <li className="small light-grey">
                                DEX Listing (Pancakeswap)
                              </li>
                              <li className="small light-grey">
                                Coin Listing (CMC & CG)
                              </li>
                              <li className="small light-grey">
                                Influencer Onboarding
                              </li>
                              <li className="small light-grey">
                                Sports Product Presale Launch
                              </li>
                              <li className="small light-grey">
                                Quarterly MNT Burning
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-style4">
                      <Card className="p-4 pe-3">
                        <div className="linelabel">
                          <p className="mb-0">Q1 2024</p>
                          <img className="line" alt="Line" src={Line} />
                          <div className="group">
                            <Dotcircle/>
                          </div>
                        </div>

                        <div className="innerCard text-left pl-3">
                        //   <h4 className="mb-3">Research</h4>
                          <div>
                            <ul>
                              <li className="small light-grey">
                                CEX Listing (MEXC)
                              </li>
                              <li className="small light-grey">
                                DEX Listing (Pancakeswap)
                              </li>
                              <li className="small light-grey">
                                Coin Listing (CMC & CG)
                              </li>
                              <li className="small light-grey">
                                Influencer Onboarding
                              </li>
                              <li className="small light-grey">
                                Sports Product Presale Launch
                              </li>
                              <li className="small light-grey">
                                Quarterly MNT Burning
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide> */}
                </Swiper>
              </Tab.Pane>
              <Tab.Pane eventKey="2023" className="twentythree">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 25,
                    },
                  }}
                  modules={[]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="card-style4">
                      <Card className="p-4 pe-3">
                        <div className="linelabel">
                          <p className="mb-0">Q1 2023</p>
                          <img className="line" alt="Line" src={Line} />
                          <div className="group">
                            <Dotcircle />
                          </div>
                        </div>

                        <div className="innerCard text-left pl-3">
                          {/* <h4 className="mb-3">Research</h4> */}
                          <div>
                            <ul>
                              <li className="small light-grey">
                                Partial unlocking of Private and Presale Tokens
                              </li>
                              <li className="small light-grey">
                                Mining Reward Distribution
                              </li>
                              <li className="small light-grey">
                                Audited Smart Contract by Certik
                              </li>
                              <li className="small light-grey">
                                Quarterly MRMINT Burning
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-style4">
                      <Card className="p-4 pe-3">
                        <div className="linelabel">
                          <p className="mb-0">Q2 2023</p>
                          <img className="line" alt="Line" src={Line} />
                          <div className="group">
                            <Dotcircle />
                          </div>
                        </div>

                        <div className="innerCard text-left pl-3">
                          {/* <h4 className="mb-3">Research</h4> */}
                          <div>
                            <ul>
                              <li className="small light-grey">
                                Partial unlock of Public Sale
                              </li>
                              <li className="small light-grey">
                                Listed on CEX (CoinStore)
                              </li>
                              <li className="small light-grey">
                                Quarterly MRMINT Burning
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-style4">
                      <Card className="p-4 pe-3">
                        <div className="linelabel">
                          <p className="mb-0">Q3 2023</p>
                          <img className="line" alt="Line" src={Line} />
                          <div className="group">
                            <Dotcircle />
                          </div>
                        </div>

                        <div className="innerCard text-left pl-3">
                          {/* <h4 className="mb-3">Research</h4> */}
                          <div>
                            <ul>
                              <li className="small light-grey">
                                Launched NFT Marketplace
                              </li>
                              <li className="small light-grey">
                                Ideation of Stepmint
                              </li>
                              <li className="small light-grey">
                                Exhibited in WBS Singapore
                              </li>
                              <li className="small light-grey">
                                Development of Stepmint
                              </li>
                              <li className="small light-grey">
                                Quarterly MRMINT Burning
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-style4">
                      <Card className="p-4 pe-3">
                        <div className="linelabel">
                          <p className="mb-0">Q4 2023</p>
                          <img className="line" alt="Line" src={Line} />
                          <div className="group">
                            <Dotcircle />
                          </div>
                        </div>

                        <div className="innerCard text-left pl-3">
                          {/* <h4 className="mb-3">Research</h4> */}
                          <div>
                            <ul>
                              <li className="small light-grey">
                                Exhibited in Crypto Expo Dubai
                              </li>
                              <li className="small light-grey">
                                Presale launch of Stepmint
                              </li>
                              <li className="small light-grey">
                                Development of Stepmint
                              </li>
                              <li className="small light-grey">
                                Ideation of Upcoming Sport Product
                              </li>
                              <li className="small light-grey">
                                Launched Stepmint Application
                              </li>
                              <li className="small light-grey">
                                Quarterly MRMINT Burning
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </Tab.Pane>
              <Tab.Pane eventKey="2024" className="twentyfour">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 25,
                    },
                  }}
                  modules={[]}
                  className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="card-style4">
                      <Card className="p-4 pe-3">
                        <div className="linelabel">
                          <p className="mb-0">Q1 2024</p>
                          <img className="line" alt="Line" src={Line} />
                          <div className="group">
                            <Dotcircle />
                          </div>
                        </div>

                        <div className="innerCard text-left pl-3">
                          {/* <h4 className="mb-3">Research</h4> */}
                          <div>
                            <ul>
                              <li className="small light-grey">
                                CEX Listing (MEXC)
                              </li>
                              <li className="small light-grey">
                                DEX Listing (Pancakeswap)
                              </li>
                              <li className="small light-grey">
                                Coin Listing (CMC & CG)
                              </li>
                              <li className="small light-grey">
                                Influencer Onboarding
                              </li>
                              <li className="small light-grey">
                                Sports Product Presale Launch
                              </li>
                              <li className="small light-grey">
                                Quarterly MRMINT Burning
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-style4">
                      <Card className="p-4 pe-3">
                        <div className="linelabel">
                          <p className="mb-0">Q2 2024</p>
                          <img className="line" alt="Line" src={Line} />
                          <div className="group">
                            <Dotcircle />
                          </div>
                        </div>

                        <div className="innerCard text-left pl-3">
                          {/* <h4 className="mb-3">Research</h4> */}
                          <div>
                            <ul>
                              <li className="small light-grey">
                                Stepmint Phase 2 Launch
                              </li>
                              <li className="small light-grey">
                                Sports Product Phase 1 Launch
                              </li>
                              <li className="small light-grey">
                                Mr Mint Blockchain Phase 1 Testnet live
                              </li>
                              <li className="small light-grey">
                                Exhibition in Token 2049 Conference
                              </li>

                              <li className="small light-grey">
                                Quarterly MRMINT Burning
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-style4">
                      <Card className="p-4 pe-3">
                        <div className="linelabel">
                          <p className="mb-0">Q3 2024</p>
                          <img className="line" alt="Line" src={Line} />
                          <div className="group">
                            <Dotcircle />
                          </div>
                        </div>

                        <div className="innerCard text-left pl-3">
                          {/* <h4 className="mb-3">Research</h4> */}
                          <div>
                            <ul>
                              <li className="small light-grey">
                                Sports Product Phase 2 Launch
                              </li>
                              <li className="small light-grey">
                                Other CEX Listing
                              </li>
                              <li className="small light-grey">
                                Mr Mint Blockchain Phase 1 Mainnet live
                              </li>
                              <li className="small light-grey">
                                Exhibition in WBS Singapore
                              </li>

                              <li className="small light-grey">
                                Quarterly MRMINT Burning
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-style4">
                      <Card className="p-4 pe-3">
                        <div className="linelabel">
                          <p className="mb-0">Q4 2024</p>
                          <img className="line" alt="Line" src={Line} />
                          <div className="group">
                            <Dotcircle />
                          </div>
                        </div>

                        <div className="innerCard text-left pl-3">
                          {/* <h4 className="mb-3">Research</h4> */}
                          <div>
                            <ul>
                              <li className="small light-grey">
                                Stepmint Phase 3 Launch
                              </li>
                              <li className="small light-grey">
                                Mr Mint Blockchain Phase 2 live
                              </li>
                              <li className="small light-grey">
                                Quarterly MRMINT Burning
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </Tab.Pane>

              <Tab.Pane eventKey="2025" className="twentyfour">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 4,
                      spaceBetween: 25,
                    },
                  }}
                  modules={[]}
                // className="mySwiper"
                >
                  <SwiperSlide>
                    <div className="card-style4">
                      <Card className="p-4 pe-3">
                        <div className="linelabel">
                          <p className="mb-0">Q1 2025</p>
                          <img className="line" alt="Line" src={Line} />
                          <div className="group">
                            <Dotcircle />
                          </div>
                        </div>

                        <div className="innerCard text-left pl-3">
                          {/* <h4 className="mb-3">Research</h4> */}
                          <div>
                            <ul>
                              <li className="small light-grey">
                                StepMint Phase 2 Launch
                              </li>
                              <li className="small light-grey">
                                Sponsorship in Legend90
                              </li>
                              <li className="small light-grey">
                                Blockchain Testnet Live
                              </li>
                              <li className="small light-grey">
                                Blockchain Delegates Program Launch
                              </li>
                              <li className="small light-grey">
                                A New Franchise Team Owned by SportsMint
                              </li>
                              <li className="small light-grey">
                                Social Media Influencer Campaign for StepMint
                              </li>


                              <li className="small light-grey">
                                Harbhajan Singh Onboarded for SportsMint as a Brand Ambassador
                              </li>
                              <li className="small light-grey">
                                Brand Media Expansion (Telecast on Star Sports, Sony Liv, DD Sports, Sony Network, JioStar)
                              </li>
                              <li className="small light-grey">
                                Onboard Saina Nehwal For StepMint as a Brand Ambassador
                              </li>
                              <li className="small light-grey">
                                Exhibit in Franchise India
                              </li>
                              <li className="small light-grey">
                                Organize PagalPan Series Event
                              </li>
                              <li className="small light-grey">
                                Social Feed & Challenger Ground Feature Launch on StepMin
                              </li>

                              <li className="small light-grey">
                                International Brand Collaboration with SportsMint
                              </li>
                              <li className="small light-grey">
                                Trial for MP Tigers
                              </li>
                              <li className="small light-grey">
                                Mr Mint Token Burning
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-style4">
                      <Card className="p-4 pe-3">
                        <div className="linelabel">
                          <p className="mb-0">Q2 2025</p>
                          <img className="line" alt="Line" src={Line} />
                          <div className="group">
                            <Dotcircle />
                          </div>
                        </div>

                        <div className="innerCard text-left pl-3">
                          {/* <h4 className="mb-3">Research</h4> */}
                          <div>
                            <ul>
                              <li className="small light-grey">
                                SportsMint Phase 2 Launch
                              </li>
                              <li className="small light-grey">
                                Mr Mint Token Listing on Tier 1 Exchange
                              </li>
                              <li className="small light-grey">
                                Blockchain Mainnet Launch
                              </li>
                              <li className="small light-grey">
                                Exhibit in Blockchain-Based Domestic and Global Events
                              </li>
                              <li className="small light-grey">
                                SportsMint Participation in Different Leagues
                              </li>
                              <li className="small light-grey">
                                Organize PagalPan Series Event
                              </li>
                              <li className="small light-grey">
                                SportsMint Celebrities Advertising During Champions Trophy & IPL
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-style4">
                      <Card className="p-4 pe-3">
                        <div className="linelabel">
                          <p className="mb-0">Q3 2025</p>
                          <img className="line" alt="Line" src={Line} />
                          <div className="group">
                            <Dotcircle />
                          </div>
                        </div>

                        <div className="innerCard text-left pl-3">
                          {/* <h4 className="mb-3">Research</h4> */}
                          <div>
                            <ul>
                              <li className="small light-grey">
                                Hackthon Programs for Blockchain
                              </li>
                              <li className="small light-grey">
                                Organize Pagalpan Series event
                              </li>
                              <li className="small light-grey">
                                Sportsmint celebrities advertisement during Asia Cup
                              </li>
                              <li className="small light-grey">
                                Global head office expansion
                              </li>
                              <li className="small light-grey">
                                StepMint Celebrity social media campaign
                              </li>

                              <li className="small light-grey">
                                Presence in Blockchain-based domestic and international events
                              </li>
                              <li className="small light-grey">
                                Sportmint physical marketing promotion in BCL trials
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="card-style4">
                      <Card className="p-4 pe-3">
                        <div className="linelabel">
                          <p className="mb-0">Q4 2025</p>
                          <img className="line" alt="Line" src={Line} />
                          <div className="group">
                            <Dotcircle />
                          </div>
                        </div>

                        <div className="innerCard text-left pl-3">
                          {/* <h4 className="mb-3">Research</h4> */}
                          <div>
                            <ul>
                              <li className="small light-grey">
                                Stepmint Phase 3 Launch
                              </li>
                              <li className="small light-grey">
                                Hackthon Programs for Blockchain
                              </li>
                              <li className="small light-grey">
                                SportsMint Celebrities Advertisement
                              </li>
                              <li className="small light-grey">
                                StepMint Celebrity social media campaign
                              </li>
                              <li className="small light-grey">
                                Presence in Blockchain-based domestic and international events
                              </li>
                            </ul>
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
}
