import React from 'react'
import Header from '../directives/header'
import 'react-accessible-accordion/dist/fancy-example.css';
import Footer from './Mrmintnew/directives/footer';


const Cookies = () => {


  return (

    <>
      <Header />
      <div id="content" className="">
        <div className="breadcrumb-wrap bg-f br-4">
          <div className="overlay bg-black op-7" />
          <div className="container">
            <div className="breadcrumb-title">
              <h2>Cookie Policy</h2>
              <ul className="breadcrumb-menu list-style">
                <li>
                  <a href="#">Home</a>
                </li>
                <li>Cookie Policy</li>
              </ul>
            </div>
          </div>
        </div>
        <div className='container'>

          <br />

          <p>
            Cookies are files place don your computer by certain websites that you
            visitor emails that you open. Using the website https://mrmint.io ,you
            consent toour use of cookies to collect and use your personal data in order
            to collect data about the use of the website, as well as to provide you with
            a personalized and more convenient online interaction.
          </p>
          <p>
            The personal data we collect may include information about how you use our
            website, your choice of Internet browser,the type of device from which you
            access our website,and where you are in the world. If any information we
            collect through cookies qualifies as personaldata, our Privacy Policy
            applies to the collection and use of such personaldata.
          </p>
          <br />
          <h4>HOW WE USE COOKIES</h4>
          <p>We collect and use cookies on our website for the following purposes:</p>
          <br />
          <h4>Technical</h4>
          <p>
            Cookies allow our website to function most efficiently, including, but not
            limited to:
          </p>
          <ul>
            <li>
              Balancing website traffic to ensure that all our user scan enjoy
              consistent and reliable access;
            </li>
            <li>
              Limiting traffic to key sections of our website to maintain stable
              performance;
            </li>
            <li>
              Maintaining our users’ consent to our Terms of Use, Privacy Policy and
              Cookie Policy.
            </li>
          </ul>
          <br />
          <h4>Ease of use</h4>
          <ul>
            <li>Cookies make it easier for you to navigate our website;</li>
            <li>Help to remember the preferences of your language and country.</li>
          </ul>
          <br />
          <h4>Analytic</h4>
          <p>
            Cookies are used to collect statistics about how you move and make
            transactions on our website, including, but not limited to:
          </p>
          <ul>
            <li>Collecting feedback on your satisfaction throughsurveys;</li>
            <li>
              Getting information about how you navigate and make transactions on our
              website so that improvements can bemade.
            </li>
          </ul>
          <br />
          <h4>Marketing</h4>
          <p>
            Cookies allow us to provide you with relevant marketing
            messages,including,but not limitedto:
          </p>
          <br />
          <ul>
            <li>
              Allowing our website to serve different versions of the page for marketing
              purposes;
            </li>
            <li>
              Allowing us and / or third parties to display relevant messages and / or
              advertisements that offer a selection of products based on your browsing
              behavior on our website and some otherwebsites.
            </li>
          </ul>
          <br />
          <p>
            Weal souse web analytics services such as Google Analytics.Google may use
            the collected data to contextualize and personalizead son its own network.
          </p>
          <br />
          <h4>Online behavioral advertising</h4>
          <p>
            Online behavioral advertising is also known as interest-based
            advertising.It’saway of serving advertisements that are more relevant to
            your interests on websites visited by you.Shared interest sare grouped to ge
            ther based on your previous web browsing activity.This allows us to display
            advertising that is relevant and useful to you.Advertising canal so bede
            live red to you based on the website content you’ve just been viewing. This
            is called retargeting.When you visit our website and navigate to other
            websites,we may leverage the cookies we have in place and our partnerships
            with online advertisers and social media networks in order to serve you with
            targeted advertising and/or to limit the number of times you see a
            particular advertisement. We collect the following information about you for
            such purposes:IP address or other unique identifiers,mobile carrier,time
            zone setting,operating system and platform. You may optout of the use of
            cookies if you don’t want to be retargeted or your advertising to be
            tailored. Optingout, however,does not mean that you will no longer receive
            online advertising. It does mean that the company or companies from which
            you opted out will no longer serve you advertising that’ stailored to your
            web preferences and us age patterns.You may instead see more advertising
            that is n’t as relevant to your preferences.
          </p>
          <br />
          <h4>Our partnership with advertising networks</h4>
          <p>
            We only share yourd at a with advertising networks in the strictest
            confidence for the unique purpose of serving more relevant advertising.We
            have established standards to ensure that you’ll only be served appropriate
            advertising.You may optout of such advertising from the advertising network
            sat any time by visiting their websitesor be referring to the section titled
            “Managing cookiepreferences”.
          </p>
          <br />
          <h4>Managing cookie preferences</h4>
          <p>
            You may refuse to accept cookies by changing the settings of your Internet
            browser. If you do not want cookies to be placed on yourdevice,you can
            control this through your browser’s settings. If you choose not toal low the
            use of cookies on our website,some are as of our website may not function
            properly or be accessible.Please note that if you delete your cookies, we
            won’t be able to know that you’ve opted out of behavioral advertising on our
            web- site; recognize that you’ve agreed to our Terms of Use Conditions,
            Privacy Policy and Cookie Policy; or remember your language and country
            preferences. This means that banners from our third-party websites will
            appear. You’ll need to accept the use of cookies and provide other
            cookie-related information again.
          </p>
          <br />
          <h4>Clickstream data</h4>
          <p>
            A click stream records which parts of a website you click on when you use
            it. We may collect click stream data when you use our website and track
            referrals from other websites to improve your online experience with us.This
            data will not bestored as part of your customer record,and will only be used
            to statistic alanalyses.
          </p>


          <br />
        </div>




      </div>
      <Footer />
    </>

  )

}
export default Cookies;